<template>
    <v-skeleton-loader v-if="loading" />
    <v-container v-else>
        <v-card>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="station.name" label="Name" required outlined />
                            <v-text-field v-model="station.aemoId" label="AEMO ID" required outlined />
                            <v-autocomplete auto-select-first v-model="bidderId" :items="bidders" item-value="id" item-text="name" label="Bidder" required filled />
                            <v-autocomplete auto-select-first v-model="ownerId" :items="owners" item-value="id" item-text="name" label="Owner" required filled />
                            <v-autocomplete auto-select-first v-model="traderId" :items="traders" item-value="id" item-text="name" label="Trader" required filled />
                            <v-autocomplete auto-select-first v-model="nodeId" :items="nodes" item-value="id" item-text="name" label="Node" required filled />
                            <v-autocomplete auto-select-first v-model="zoneId" :items="zones" item-value="id" item-text="name" label="TNSP Zone" required filled />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="heatRate" label="Heat Rate" type="number" outlined clearable />
                            <v-list outlined rounded>
                                <v-list-item>
                                    <v-list-item-title>Auxillary Factors</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-for="(factor, index) in station.auxillaryFactors" :key="index">
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-menu v-model="factor.show" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="factor.effectiveDate" label="Effective Date" readonly v-bind="attrs" v-on="on" hide-details />
                                                    </template>
                                                    <v-date-picker v-model="factor.effectiveDate" @input="factor.show = false" />
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-model="factor.auxillaryFactor" label="Auxillary Factor" required hide-details />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon @click="removeAuxillaryFactor(index)"><v-icon>mdi-delete</v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-btn @click="addAuxillaryFactor" block disabled>Add (not working yet)</v-btn>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn min-width="100" :to="{ name: 'station-list' }">Back</v-btn>
                <v-btn min-width="100" class="primary" @click="save" :loading="saving">Save</v-btn>
                <v-alert v-if="alert" v-model="alert.visible" class="ma-3" :type="alert.type" transition="scale-transition" shaped text dense>
                    {{ alert.message }}
                </v-alert>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Station',

    data() {
        return {
            loading: true,
            saving: false,
            valid: true,
            station: null,
            alert: null,
            nodes: [],
            zones: [],
            bidders: [],
            owners: [],
            traders: []
        };
    },

    async created() {
        await this.load();
    },

    computed: {
        id: function () {
            return this.$route.params.id;
        },
        nodeId: {
            get: function () {
                return this.station.node.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.station.node = this.nodes.find(i => i.id === value);
                }
            }
        },
        zoneId: {
            get: function () {
                return this.station.tnspZone.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.station.tnspZone = this.zones.find(i => i.id === value);
                }
            }
        },
        bidderId: {
            get: function () {
                return this.station.bidder.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.station.bidder = this.bidders.find(i => i.id === value);
                }
            }
        },
        ownerId: {
            get: function () {
                return this.station.owner.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.station.owner = this.owners.find(i => i.id === value);
                }
            }
        },
        traderId: {
            get: function () {
                return this.station.trader.id;
            },
            set: function (value) {
                if (value !== null) {
                    this.station.trader = this.traders.find(i => i.id === value);
                }
            }
        },
        heatRate: {
            get: function () {
                if (this.station.heatRate) {
                    return this.station.heatRate.heatRate;
                }
                return null;
            },
            set: function (value) {
                if (value) {
                    this.station.heatRate = {
                        heatRate: value
                    };
                } else {
                    this.station.heatRate = null;
                }
            }
        }
    },

    methods: {
        async save() {
            try {
                this.saving = true;
                this.alert = null;
                const response = await axios.put(`/api/stations/${this.id}`, this.station);
                this.alert = { message: 'Saved', type: response.status >= 200 && response.status < 300 ? 'success' : 'error' };
                this.saving = false;
            } catch {
                this.alert = { message: 'Error', type: 'error' };
                this.saving = false;
            }
        },
        async load() {
            this.loading = true;
            this.bidders = (await axios.get('/api/bidders')).data;
            this.owners = (await axios.get('/api/owners')).data;
            this.traders = (await axios.get('/api/traders')).data;
            this.nodes = (await axios.get('api/nodes')).data;
            this.zones = (await axios.get('/api/zones')).data;
            this.station = (await axios.get(`/api/stations/${this.id}`)).data;
            this.loading = false;
        },
        addAuxillaryFactor() {
            this.station.auxillaryFactors.push({
                stationId: this.station.id,
                effectiveDate: new Date().toISOString().slice(0, 10),
                auxillaryFactor: 1.0
            });
        },
        removeAuxillaryFactor(index) {
            this.station.auxillaryFactors.splice(index, 1);
        }
    },

    watch: {
        async $route() {
            await this.load();
        }
    }
};
</script>
